import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => (
    <header className="header-top">
        <nav className="nav">
            <Link to="/" className="nav-link">Home</Link>
            <Link to="/about" className="nav-link">About Me</Link>
            <Link to="/experience" className="nav-link">Experience</Link>
            <Link to="/projects" className="nav-link">Projects</Link>
            <Link to="/hire-me" className="nav-link">Hire Me</Link>
        </nav>
    </header>
);

export default Header;
