import React from 'react';
import ContactForm from '../components/ContactForm';
import { Link } from 'react-router-dom';
import "./HireMe.css";

const HireMe = () => (
    <div className="content">
        <h2>Hire Me</h2>
        <div className="measure-wide lh-copy f5 black-90" style={{ position: 'relative', color: 'var(--font-color)' }}>
            <p>
                <strong>Available for Consulting Engagements and Contract Projects</strong>: As a seasoned Product & Engineering Leader with over 20 years in the field, I offer my expertise for both consulting engagements and contract projects. I specialize in helping businesses build and scale data-driven solutions, particularly in database technology and industrial IoT.  I can lead your software engineering projects from idea to launch while driving technical innovation as a Fractional <span className="small-caps">CTO</span> — a role that brings the benefits of a Chief Technology Officer to your business on a flexible, part-time basis. I'm also happy to jump in and help your team with coding as needed. I have extensive experience in JavaScript/TypeScript, Node.js, React, <span className="small-caps">REST</span> APIs, SQL+Python Jupyter Notebooks, <span className="small-caps">AWS</span>, and many other technologies.
            </p>
            <p>
                <strong>I help companies launch products</strong> with a clear product strategy and smart technology choices. I can define product features and user experience to ensure your engineering teams can deliver efficiently. I review and advise on technology and architecture decisions, and I'm always ready to contribute as a high-performing software engineer when needed.
            </p>
            <p>
                <strong>I help teams deliver software faster</strong> that customers love. My approach is hands-on, helping teams deliver robust, user-centric software efficiently. I focus wherever you need it most, from understanding customer needs, making strategic trade-offs, and supporting cross-functional teams to diving in and writing code or fixing bugs on key parts of the codebase.
            </p>
            <p>
                <strong>I help executives clarify strategy</strong> by aligning and articulating a product and technical strategy to align your team or raise your next round of funding.  My ultimate success is measured by the success of your team and the growth of your product revenue.
            </p>
            <p>
                Discover more about my journey and philosophy in <Link to="/about" title="wikilink">About Me</Link>, and explore my detailed <Link to="/experience" title="wikilink">Experience & Skills</Link>.
            </p>
            <div className="right margin">
                <p>Wondering how a Fractional <span className="small-caps">CTO</span> can revolutionize your business? Learn at <Link to="/what-is-a-fractional-cto" title="wikilink">What is a Fractional CTO?</Link>.</p>
            </div>
            <h2 id="activities">Activities</h2>
            <p>Below are some of the activities I’ve performed for other teams.</p>
            <h3 id="build"><img className="emoji" draggable="false" alt="🔨" src="//twemoji.maxcdn.com/36x36/1f528.png" /> Build</h3>
            <ul>
                <li>Transform executive vision into plans engineers can execute</li>
                <li>Articulate a Product Strategy</li>
                <li>Customer & <span className="small-caps">UX</span> research</li>
                <li>Design, code & operate SaaS applications.</li>
                <li>Lead initiatives to dramatically improve quality, performance or scalability large legacy code bases</li>
                <li>Recruit and manage engineers: Employees and freelancers</li>
                <li>User experience design - <em>I’m not a designer, but I can get a new feature off the ground.</em></li>
            </ul>
            <h3 id="operate"><span className="small-caps">⚙️</span> Operate</h3>
            <ul>
                <li>Define and monitor KPIs</li>
                <li>Build cross-team collaboration around product</li>
                <li>Instrument and analyze product usage</li>
                <li>Revamp engineering agile processes</li>
                <li>Establish practices to improve service reliability</li>
                <li>Instill engineering excellence</li>
            </ul>
            <h3 id="product-launch"><img className="emoji" draggable="false" alt="🚀" src="//twemoji.maxcdn.com/36x36/1f680.png" /> Product Launch</h3>
            <ul>
                <li>Product briefs for marketing and sales</li>
                <li>Demo scripts for sales</li>
                <li>Customer-facing copy and documentation</li>
                <li>Support fundraising pitch deck, investor calls</li>
            </ul>
            <h2 id="engagement-packages"><img className="emoji" draggable="false" alt="📦" src="//twemoji.maxcdn.com/36x36/1f4e6.png" /> Engagement Packages</h2>
            <p>I generally work with multiple clients simultaneously and I have standard pricing packages that reflect this engagement style as outlined below. I am available for dedicated engagements on a case-by-case basis, but it generally will take 4-8 weeks lead time.</p>
            <div className="right margin">
                <p>Want to chat? Reach out via email (<a href="mailto:bjarne@christiansen.com" className="email" target="_blank" rel="noopener noreferrer">bjarne@christiansen.com</a>) or <a href="https://www.linkedin.com/in/bjchrist/" target="_blank" rel="noopener noreferrer"><img src="https://cdn.blot.im/blog_d86060336d9c4623be1d9004da02510b/_image_cache/0cf0dc7d-99c1-4ead-809f-c4ba2dedc82e.svg" alt="LinkedIn Logo" width="16" height="16" /> LinkedIn</a>.</p>
            </div>
            <p>I want every one of my clients to be my advocates. So I am 100% dedicated to making every engagement successful. Each of the packages below include the following:</p>
            <ul>
                <li>Onboarding with the team</li>
                <li>No restrictions on projects</li>
                <li>All work in client tools + processes</li>
                <li>No tracked hours — I focus on results, not time</li>
            </ul>
            <div className="engagement-packages">
                <table>
                    <thead>
                        <tr className="header">
                            <th><strong>Package</strong></th>
                            <th><strong>Price</strong></th>
                            <th><strong>Best For</strong></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="odd">
                            <td><strong>Coaching</strong></td>
                            <td>$3,525 /mo</td>
                            <td>Targeted Product & Technical Feedback: <br />• About 5 hours per week <br />• Renew monthly</td>
                        </tr>
                        <tr className="even">
                            <td><strong>Executing</strong></td>
                            <td>$6,650 /mo</td>
                            <td>Project Leadership: <br />• About 10 hours per week <br />• Product Management and/or Coding<br />• Technical Reviews & Coaching <br />• Join Sales Calls <br />• Take investor calls, added to investor decks as Fractional <span className="small-caps">CTO</span><br />• Renew quarterly</td>
                        </tr>
                        <tr className="odd">
                            <td><strong>Scaling</strong></td>
                            <td>$12,500 /mo</td>
                            <td>Team & Product Leadership: <br />• About 20 hours per week<br />• Includes<span className="push-double"></span> <span className="pull-double">“</span>Executing” and:<br />• Hire, Mentor & Manage Engineers <br />• Renew quarterly</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="contact-form-section">
                <h2>Contact Me</h2>
                <p>Have a question or want to discuss a project? Feel free to reach out! I'm always happy to connect and explore how my experience can help your business. Or you can use the form below:</p>
                <Link to="/contact" className="button">
                    Contact Me
                </Link>
                <ContactForm />
            </div>
        </div>
    </div>
);

export default HireMe;