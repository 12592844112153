import React from 'react';
import ContactForm from '../components/ContactForm';

const Contact = () => (
    <div className="content">
        <h2>Contact Me</h2>
        <p>
            Have a question? Want to discuss a project?  Feel free to reach out! I'm always happy to connect and explore how my experience can help your business.
        </p>
        <ContactForm />
    </div>
);

export default Contact;