import React from 'react';
import './Experience.css';

const Experience = () => (
    <div className="content">
        <h2>Experience</h2>
        <div className="experience-item">
            <h3>Fractional CTO / Product Owner | Various Companies | [Start Date - Present]</h3>
            <ul>
                <li>Led the development and implementation of local-first enablement strategies for high-impact special products, empowering users with enhanced offline capabilities and optimizing for edge computing environments.</li>
                <li>Managed project budgets, timelines, and resources, ensuring successful delivery of key projects within the database and IoT sectors.</li>
                <li>Designed and implemented a highly efficient CSV parser in Rust, contributing to improved data processing efficiency and scalability for data-driven applications.</li>
                <li>Acted as Head of Special Projects, Head of Research, and CTO, providing technical guidance and leadership across multiple teams to drive strategic technology initiatives.</li>
                <li>Conducted company assessments and alignments, ensuring cross-functional collaboration between sales, marketing, and engineering teams to align business goals with technological solutions.</li>
                <li>Successfully delivered impactful product solutions for companies like Seeded Home, Glide, and Flatfile, leveraging expertise in data management, distributed systems, and cloud platforms.</li>
            </ul>
        </div>

        {/* Add other experience sections in a similar format */}

        <h2>Skills</h2>
        <ul>
            <li><strong>Database Technologies:</strong></li>
            <ul>
                <li>Relational Databases: PostgreSQL, MySQL, SQLite</li>
                <li>NoSQL Databases: MongoDB, Cassandra, Redis</li>
                <li>Distributed Databases: Experience with sharding, replication, and distributed transactions</li>
                <li>Data Modeling & Optimization: Experience with database design, query optimization, and performance tuning</li>
                <li>Storage Engine Optimization: Experience with low-level storage engine implementation, indexing, and data compression</li>
                <li>Data Security: Understanding of database security best practices, access control, and encryption</li>
            </ul>
            <li><strong>IoT Technologies:</strong></li>
            <ul>
                <li>Local-first Enablement: Expertise in building offline-first applications and optimizing for edge computing environments.</li>
                <li>Data Collection & Processing: Experience with sensor data acquisition, data aggregation, and real-time data processing.</li>
                <li>Communication Protocols: Familiar with MQTT, CoAP, AMQP, and other communication protocols used in IoT.</li>
                <li>Cloud Platforms: Experience with AWS IoT, Azure IoT Hub, or Google Cloud IoT Core.</li>
            </ul>
            <li><strong>Software Development:</strong></li>
            <ul>
                <li>Programming Languages: Rust, C++, Python, Java, JavaScript</li>
                <li>Development Tools: Docker, Kubernetes, Git, CI/CD pipelines</li>
                <li>Agile Methodologies: Scrum, Kanban, XP</li>
            </ul>
            <li><strong>Leadership & Management:</strong></li>
            <ul>
                <li>Team Leadership: Experience leading cross-functional teams, mentoring team members, and fostering a collaborative work environment.</li>
                <li>Product Management: Strong understanding of product development lifecycles, from ideation to launch and beyond.</li>
                <li>Project Management: Experience managing project budgets, timelines, and resources to ensure successful project delivery.</li>
            </ul>
        </ul>
    </div>
);

export default Experience;