import React from 'react';
import {
    FacebookShareButton,
    TwitterShareButton,
    LinkedinShareButton,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
} from 'react-share';

const BlogPost = ({ post }) => {
    if (!post) {
        return <div>Loading...</div>;
    }

    const { title, content, date } = post;
    const url = window.location.href;

    return (
        <article className="blog-post" aria-label={`Blog post titled ${title}`}>
            <h1 className="blog-post-title">{title}</h1>
            <p className="blog-post-date">{date}</p>
            <div className="blog-post-content" dangerouslySetInnerHTML={{ __html: content }} />
            <div className="social-share" aria-label="Share this post">
                <FacebookShareButton url={url} quote={title} aria-label="Share on Facebook">
                    <FacebookIcon size={32} round />
                </FacebookShareButton>
                <TwitterShareButton url={url} title={title} aria-label="Share on Twitter">
                    <TwitterIcon size={32} round />
                </TwitterShareButton>
                <LinkedinShareButton url={url} title={title} aria-label="Share on LinkedIn">
                    <LinkedinIcon size={32} round />
                </LinkedinShareButton>
            </div>
        </article>
    );
};

export default BlogPost;
