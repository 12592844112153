import React from 'react';
import { Link } from 'react-router-dom';
import profilePic from '../assets/profile.jpg';

const Sidebar = () => (
    <div className="sidebar">
        <div className="profile">
            <img src={profilePic} alt="Bjarne Christiansen" className="profile-pic" />
            <h1>Bjarne Christiansen</h1>
            <p>Product Focused Technical Executive Leader living in San Francisco</p>
        </div>
        <nav className="nav">
            <Link to="/" className="nav-link">Home</Link>
            <Link to="/about" className="nav-link">About Me</Link>
            <Link to="/experience" className="nav-link">Experience & Skills</Link>
            <Link to="/projects" className="nav-link">Projects</Link>
            <Link to="/hire-me" className="nav-link">Hire Me</Link>
        </nav>
    </div>
);

export default Sidebar;
