import React from 'react';
import { Link } from 'react-router-dom';

const BlogNavigation = ({ posts, currentSlug }) => {
    const currentIndex = posts.findIndex(post => post.slug === currentSlug);
    const previousPost = posts[currentIndex - 1];
    const nextPost = posts[currentIndex + 1];

    return (
        <div className="blog-navigation">
            {previousPost && (
                <Link to={`/blog/${previousPost.slug}`} className="previous-post">
                    &larr; {previousPost.title}
                </Link>
            )}
            {nextPost && (
                <Link to={`/blog/${nextPost.slug}`} className="next-post">
                    {nextPost.title} &rarr;
                </Link>
            )}
        </div>
    );
};

export default BlogNavigation;
