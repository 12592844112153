import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Experience from './pages/Experience';
import Projects from './pages/Projects';
import HireMe from './pages/HireMe';
import BlogPostPage from './pages/BlogPostPage';
import WhatIsFractionalCTO from './pages/WhatIsFractionalCTO';
// Removed NotFound import
import Contact from './pages/Contact';
import './styles.css';

const App = () => {
  const [isSidebar, setIsSidebar] = useState(false);
  const [darkMode, setDarkMode] = useState(false); // State for dark mode

  // Update Dark Mode based on LocalStorage
  useEffect(() => {
    const savedMode = localStorage.getItem('darkMode');
    if (savedMode) {
      setDarkMode(JSON.parse(savedMode));
    }
  }, []);

  const toggleNavigation = () => {
    setIsSidebar(!isSidebar);
  };

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
    localStorage.setItem('darkMode', JSON.stringify(darkMode));
  };

  // Apply dark mode class based on state
  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  }, [darkMode]);

  return (
    <Router>
      <Helmet>
        <title>Bjarne Christiansen's Blog</title>
        <meta
          name="description"
          content="Bjarne Christiansen's blog on software development, product management, and more."
        />
        <meta
          name="keywords"
          content="Bjarne Christiansen, software development, product management, blog, technology, leadership"
        />
      </Helmet>
      <div className={`app-container ${isSidebar ? 'side' : 'top'}`}>
        {isSidebar ? <Sidebar /> : <Header />}
        <div className="content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/experience" element={<Experience />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/hire-me" element={<HireMe />} />
            <Route path="/blog/:slug" element={<BlogPostPage />} />
            <Route path="/what-is-a-fractional-cto" element={<WhatIsFractionalCTO />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </div>
        <Footer />
        <button className="toggle-nav" onClick={toggleNavigation}>
          Toggle Navigation Position
        </button>
        <button className="toggle-dark-mode" onClick={toggleDarkMode}>
          {darkMode ? 'Switch to Light Mode' : 'Switch to Dark Mode'}
        </button>
      </div>
    </Router>
  );
};

export default App;