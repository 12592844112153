import React from 'react';

const About = () => (
    <div>
        <h2>About Me</h2>
        <p>I'm Bjarne Christiansen, a data-driven product leader with over 20 years of experience in building and scaling successful software products. My passion lies in leveraging technology to solve complex problems and drive business growth. I've spent my career navigating the dynamic world of startups, leading teams, and building innovative solutions.</p>

        <p>I started my journey as a software developer, gaining experience in embedded systems and mobile application development. This led me to co-found Realm, a mobile database platform that reached billions of installations, where I honed my skills in product development, fundraising, and team management. Following this, I founded Cohera, an industrial IoT platform that leverages edge computing and local-first data management to provide real-time insights for businesses.</p>

        <p>Today, I am a sought-after consultant, acting as a fractional CTO/CPO for various companies across different industries. I bring a unique combination of technical expertise, product leadership, and strategic thinking to help businesses achieve their goals.</p>

        <h3>My Strengths</h3>
        <ul>
            <li><strong>Strategic Vision:</strong> I have a knack for identifying high-impact technology investments that align with business goals.</li>
            <li><strong>Technical Depth:</strong> I'm deeply proficient in database technologies, distributed systems, and edge computing.</li>
            <li><strong>Leadership and Collaboration:</strong> I believe in building strong teams, fostering collaboration, and empowering individuals to succeed.</li>
        </ul>
    </div>
);

export default About;
