import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import matter from 'gray-matter';
import SearchBar from './SearchBar';
import lunr from 'lunr';

const BlogList = () => {
    const [posts, setPosts] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const searchIndexRef = useRef(null);

    useEffect(() => {
        const fetchPosts = async () => {
            setIsLoading(true);
            const context = require.context('../blogPosts', false, /\.md$/);
            const postsData = await Promise.all(
                context.keys().map(async (key) => {
                    const content = await import(`../blogPosts/${key.replace('./', '')}`);
                    const meta = matter(content.default);
                    return {
                        ...meta.data,
                        content: meta.content,
                        slug: key.replace('./', '').replace('.md', ''),
                        tags: meta.data.tags || [],
                        date: meta.data.date,
                    };
                })
            );

            // Create Lunr index (outside the forEach loop)
            const index = lunr(function () {
                this.field('title');
                this.field('content');
                this.field('excerpt');
                this.field('tags');

                postsData.forEach((post, index) => {
                    this.add({
                        id: index,
                        title: post.title,
                        content: post.content,
                        excerpt: post.excerpt,
                        tags: post.tags.join(', '),
                    });
                });
            });

            setPosts(postsData);
            searchIndexRef.current = index;
            setIsLoading(false);
        };

        fetchPosts();
    }, []);

    // Re-index when posts changes 
    useEffect(() => {
        if (posts.length > 0 && searchIndexRef.current) {
            const index = lunr(function () {
                this.field('title');
                this.field('content');
                this.field('excerpt');
                this.field('tags');

                posts.forEach((post, index) => {
                    this.add({
                        id: index,
                        title: post.title,
                        content: post.content,
                        excerpt: post.excerpt,
                        tags: post.tags.join(', '),
                    });
                });
            });

            searchIndexRef.current = index;
        }
    }, [posts]);

    // Partial Word Search Logic
    const filteredPosts = searchTerm
        ? searchIndexRef.current.search(
            // Add wildcard '*' for partial word search
            `${searchTerm}*`,
            {
                fields: {
                    title: { boost: 2 },
                    content: { boost: 1 },
                    excerpt: { boost: 1.5 },
                    tags: { boost: 1 },
                },
                bool: 'OR',
            }
        ).map(({ ref }) => posts[ref])
        : posts;

    return (
        <div>
            <h2>Blog Posts</h2>
            {isLoading ? ( // Show loading indicator
                <p>Loading blog posts...</p>
            ) : (
                <>
                    <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
                    <ul>
                        {filteredPosts.map((post) => (
                            <li key={post.slug}>
                                <Link to={`/blog/${post.slug}`}>
                                    <h3>{post.title}</h3>
                                    <p>{post.excerpt}</p>
                                    <p className="blog-post-date">{new Date(post.date).toLocaleDateString()}</p>
                                </Link>
                            </li>
                        ))}
                    </ul>
                </>
            )}
        </div>
    );
};

export default BlogList;
