import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchPosts } from '../utils';
import BlogPost from '../components/BlogPost';
import BlogNavigation from '../components/BlogNavigation';

const BlogPostPage = () => {
    const { slug } = useParams();
    const [post, setPost] = useState(null);
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        const loadPost = async () => {
            const allPosts = await fetchPosts();
            const foundPost = allPosts.find(p => p.slug === slug);
            setPost(foundPost);
            setPosts(allPosts);
        };

        loadPost();
    }, [slug]);

    return (
        <div className="blog-post-container">
            {post ? (
                <>
                    <BlogPost post={post} />
                    <BlogNavigation posts={posts} currentSlug={slug} />
                </>
            ) : (
                <div>Loading...</div>
            )}
        </div>
    );
};

export default BlogPostPage;
