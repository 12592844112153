import React, { useState } from 'react';

const ContactForm = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [submitted, setSubmitted] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true);
        // Here you can add your form submission logic, e.g., sending the form data to a server or email service
    };

    return (
        <div className="pa3 pa5-ns">
            <h2>Contact Me</h2>
            {submitted ? (
                <p>Thank you for your message! I will get back to you soon.</p>
            ) : (
                <form onSubmit={handleSubmit} className="measure center">
                    <div className="mb3">
                        <label className="db fw6 lh-copy f6" htmlFor="name">Name</label>
                        <input
                            className="input-reset ba b--black-20 pa2 mb2 db w-100"
                            type="text"
                            id="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                    <div className="mb3">
                        <label className="db fw6 lh-copy f6" htmlFor="email">Email</label>
                        <input
                            className="input-reset ba b--black-20 pa2 mb2 db w-100"
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="mb3">
                        <label className="db fw6 lh-copy f6" htmlFor="message">Message</label>
                        <textarea
                            className="input-reset ba b--black-20 pa2 mb2 db w-100"
                            id="message"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit" className="button">Submit</button>
                </form>
            )}
        </div>
    );
};

export default ContactForm;
