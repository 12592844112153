import React from 'react';
import BlogList from '../components/BlogList';

const Home = () => (
    <div>
        <h1>Welcome to Bjarne Christiansen's Blog</h1>
        <BlogList />
    </div>
);

export default Home;
