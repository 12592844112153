import React from 'react';

const Footer = () => (
    <footer className="footer">
        <div className="social-icons">
            <a href="https://twitter.com/username" className="social-icon" aria-label="Twitter">
                <svg viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22.46 6c-.77.35-1.6.58-2.47.69a4.27 4.27 0 001.88-2.36 8.54 8.54 0 01-2.7 1.03A4.23 4.23 0 0015.5 4a4.24 4.24 0 00-4.24 4.24c0 .33.03.65.1.96A12.04 12.04 0 013 5.15a4.25 4.25 0 001.32 5.65 4.19 4.19 0 01-1.92-.53v.05a4.25 4.25 0 003.4 4.16 4.27 4.27 0 01-1.92.07 4.25 4.25 0 003.97 2.95A8.5 8.5 0 012 19.54a11.99 11.99 0 006.48 1.9c7.8 0 12.06-6.46 12.06-12.06v-.55A8.55 8.55 0 0024 4.56a8.58 8.58 0 01-2.54.7z" />
                </svg>
            </a>
            <a href="https://linkedin.com/in/username" className="social-icon" aria-label="LinkedIn">
                <svg viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.5 3h-15A1.5 1.5 0 003 4.5v15A1.5 1.5 0 004.5 21h15a1.5 1.5 0 001.5-1.5v-15A1.5 1.5 0 0019.5 3zm-11.75 14H5.5V10.5h2.25V17zm-1.12-7.75a1.25 1.25 0 110-2.5 1.25 1.25 0 010 2.5zm10.62 7.75h-2.25V14c0-.76-.01-1.74-1.06-1.74-1.06 0-1.22.83-1.22 1.68v3.06H11V10.5h2.16v.9h.03c.3-.56 1.02-1.16 2.11-1.16 2.26 0 2.68 1.49 2.68 3.43V17z" />
                </svg>
            </a>
            <a href="https://github.com/username" className="social-icon" aria-label="GitHub">
                <svg viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 2C6.48 2 2 6.48 2 12c0 4.42 2.87 8.17 6.84 9.49.5.09.68-.22.68-.48v-1.7c-2.78.6-3.37-1.35-3.37-1.35-.45-1.15-1.11-1.46-1.11-1.46-.9-.62.07-.6.07-.6 1 .07 1.53 1.03 1.53 1.03.88 1.5 2.31 1.06 2.87.81.09-.64.34-1.06.62-1.3-2.22-.25-4.56-1.11-4.56-4.94 0-1.09.39-1.99 1.03-2.69-.1-.25-.45-1.26.1-2.62 0 0 .84-.27 2.75 1.02a9.58 9.58 0 015 0c1.9-1.29 2.75-1.02 2.75-1.02.55 1.36.2 2.37.1 2.62.64.7 1.03 1.6 1.03 2.69 0 3.84-2.35 4.69-4.59 4.93.35.3.66.91.66 1.84v2.73c0 .27.18.59.69.48A10.013 10.013 0 0022 12c0-5.52-4.48-10-10-10z" />
                </svg>
            </a>
        </div>
        <small>© 2024 Bjarne Christiansen. All Rights Reserved</small>
    </footer>
);

export default Footer;
