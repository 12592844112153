import React from 'react';
import RealmThumbnail from '../assets/realm-thumbnail.jpg';
import CoheraThumbnail from '../assets/cohera-thumbnail.jpg';
import OpenSourceProjectThumbnail from '../assets/opensource-project-thumbnail.jpg'; // Add placeholder thumbnail
import "./Projects.css";

const Projects = () => (
    <div className="content">
        <h2>Projects</h2>

        <div className="project">
            <img src={RealmThumbnail} alt="Realm Project Thumbnail" />
            <h3>Realm (Mobile Database Platform)</h3>
            <p>
                Realm is a mobile database platform that revolutionized the way developers build and deploy applications. As co-founder and COO, I played a key role in developing and launching the platform, raising over $40 million in funding, and building a robust developer community. Realm's capabilities include offline-first data synchronization, efficient data storage, and a user-friendly API, making it ideal for building responsive and performant mobile applications.
            </p>
            <a href="https://realm.io" target="_blank" rel="noopener noreferrer">
                Learn More
            </a>
        </div>

        <div className="project">
            <img src={CoheraThumbnail} alt="Cohera Project Thumbnail" />
            <h3>Cohera (Industrial IoT Platform)</h3>
            <p>
                Cohera is a modular sensor platform designed to provide real-time insights for industrial condition monitoring. As founder and CEO, I led the development and launch of Cohera, focusing on building a fast, seamless, and accessible platform for industrial environments. Cohera leverages edge computing to deliver actionable data, enabling businesses to improve machine maintenance, optimize resource allocation, and enhance operational efficiency.
            </p>
            <a href="https://cohera.co" target="_blank" rel="noopener noreferrer">
                Learn More
            </a>
        </div>

        <div className="project">
            <img src={OpenSourceProjectThumbnail} alt="Open Source Project Thumbnail" />
            <h3>Open Source Database Project (e.g., "My Open Source Project")</h3>
            <p>
                I contributed to [Project Name], an open source database project focused on [brief description of project goal]. My contributions included [brief description of your role and contributions, e.g., implementing a new feature, optimizing performance, fixing bugs]. You can find the project repository on GitHub at [link to repository].
            </p>
            <a href="https://github.com/your-username/project-name" target="_blank" rel="noopener noreferrer">
                View Project on GitHub
            </a>
        </div>

    </div>
);

export default Projects;
