import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import ErrorBoundary from './components/ErrorBoundary';
import { Buffer } from 'buffer';
import ReactGA from 'react-ga';
import './index.css';

const TRACKING_ID = process.env.REACT_APP_TRACKING_ID; // Access the tracking ID from environment variables
ReactGA.initialize(TRACKING_ID);

window.Buffer = Buffer;

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>
);
