import React from 'react';

const SearchBar = ({ searchTerm, setSearchTerm }) => (
    <input
        type="text"
        placeholder="Search blog posts..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="pa2 ba b--black-20 search-bar"
    />
);

export default SearchBar;
