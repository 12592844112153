import matter from 'gray-matter';
import { remark } from 'remark';
import html from 'remark-html';
import gfm from 'remark-gfm';

export const fetchPosts = async () => {
    try {
        const context = require.context('../blogPosts', false, /\.md$/);
        const posts = await Promise.all(
            context.keys().map(async key => {
                const fileName = key.slice(2);
                const content = await context(key);
                if (!content.default) {
                    throw new Error('No content found in the file');
                }
                const meta = matter(content.default);

                const processedContent = await remark()
                    .use(html)
                    .use(gfm)
                    .process(meta.content);

                return {
                    ...meta.data,
                    slug: fileName.replace(/\.md$/, ''),
                    content: processedContent.toString(),
                };
            })
        );
        return posts;
    } catch (error) {
        console.error('Error fetching posts:', error);
        throw error;
    }
};
